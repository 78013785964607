/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, CookieManager, Swiper, Plyr) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
            ;

        $("span.Apple-tab-span").parents('p').addClass('sub-list');

        /* -------------- Video home -------------- */
        const blockVideo = document.querySelector('.block-video');

        if (blockVideo) {

            const videoCtr = blockVideo.querySelector('.video-ctr');
            const
                iframe      = videoCtr.querySelector('iframe'),
                videoPoster = videoCtr.querySelector('.video-poster'),
                playButton  = videoCtr.querySelector('.play-button')
            ;

            playButton.addEventListener('click', (e) => {

                if (videoPoster) {
                    $(videoPoster).fadeOut();
                }

                $(playButton).fadeOut();

                iframe.src += (iframe.src.indexOf("?") > -1 ? "&" : "?") + "autoplay=1"

            });

        }


        /* -------------- Cookie Manager -------------- */
        new CookieManager($cookieBanner, {
            name: 'adveris_cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            },
            onAccept: () => {
                console.log('accpeted')
            },
            onRefuse: () => {
                console.log('refused')
            }
        });

        /* -------------- Tabs -------------- */
        $('.onglet').click(function () {
            $('.onglet').removeClass('active');
            $(this).addClass('active');

            $('.description').removeClass('active');
            var tab_id = $(this).attr('data-tab');
            $("#" + tab_id).addClass('active');
        });

        /* -------------- Scroll top -------------- */
        $(window).scroll(function () {
            if ($(this).scrollTop() > 200) {
                $('.scrollToTop').fadeIn();
            } else {
                $('.scrollToTop').fadeOut();
            }
        });
        $('.scrollToTop').click(function () {
            $('html, body').animate({ scrollTop: 0 }, 800);
            return false;
        });

        /* -------------- Ancres -------------- */
        jQuery('a[href*=\\#]').click(function (event) {
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 200
            }, 1000);
            event.preventDefault();
        });

        /* -------------- Scroll top -------------- */
        $(window).scroll(function () {
            if ($(this).scrollTop() > 80) {
                $('header').addClass('active');
            } else {
                $('header').removeClass('active');
            }
        });

        /* -------------- Dropdown -------------- */
        jQuery(".item-logo-groupe").hover(function () {
            var isHovered = jQuery(this).is(":hover");
            if (isHovered) {
                jQuery(".item-logo ul").stop().slideDown(300);
            } else {
                jQuery(".item-logo ul").stop().slideUp(300);
            }
        });

        /* -------------- Dropdown -------------- */
        jQuery(".input-group-elem").hover(function () {
            var isHovered = jQuery(this).is(":hover");
            if (isHovered) {
                jQuery(".dropdown-menu2").stop().slideDown(300);
            } else {
                jQuery(".dropdown-menu2").stop().slideUp(300);
            }
        });


        /* -------------- Swiper home -------------- */
        var swiper = new Swiper("#page-home .banner", {
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            speed: 2500,
            loop: true,
            pagination: {
                el: "#page-home .banner .swiper-pagination",
                clickable: true
            },
            on: {
                init: function () {
                    $(".swiper-progress-bar").removeClass("animate");
                    $(".swiper-progress-bar").removeClass("active");
                    $(".swiper-progress-bar").eq(0).addClass("animate");
                    $(".swiper-progress-bar").eq(0).addClass("active");
                },
                slideChangeTransitionStart: function () {
                    $(".swiper-progress-bar").removeClass("animate");
                    $(".swiper-progress-bar").removeClass("active");
                    $(".swiper-progress-bar").eq(0).addClass("active");
                },
                slideChangeTransitionEnd: function () {
                    $(".swiper-progress-bar").eq(0).addClass("animate");
                }
            }
        });

        /* -------------- Swiper home news -------------- */
        var swiper = new Swiper("#page-home #news .swiper-container", {
            autoplay: {
                delay: 4500,
                disableOnInteraction: false
            },
            navigation: {
                nextEl: '.swiper-button-next2',
                prevEl: '.swiper-button-prev2'
            },
            slidesPerView: 1,
            speed: 450,
            loop: true
        });

        /* -------------- Product accordion Mobile -------------- */

        let isMobile = window.matchMedia("only screen and (max-width: 991px)").matches;
        if (isMobile) {
            $('.accordion_mob').click(function () {
                $('.accordion_mob').removeClass('active');
                $(this).addClass('active');

                $('.accordion_content_mob').slideUp('slow');
                $(this).next().slideDown('slow');
            });
        }



        const player = new Plyr('#plyr');

        $body.on('loaderEnd', () => console.log('ended'))

        /*
        |
        | Captcha BOT
        |------------
        */


        $( document ).ready(function() {
            setTimeout(() => {
                $('#captchaCode_CaptchaImageDiv > a').attr('title', 'Captcha à reproduire.')
            }, 100)
        })
        


        /*
        |
        | filters
        |------------
        */
        $('#filter').on('click', '.filter-trigger', function () {
            let $trigger = $(this),
                $container = $trigger.parents('.filter-container'),
                wasOpen = $container.hasClass('open');

            $('.filter-container.open').toggleClass('open', false);

            if (!wasOpen) {
                $container.toggleClass('open');
            }
        });

        $('#filter').on('click', '.filter', function () {
            $(this).parents('.filter-items').find('.filter.active').toggleClass('active', false);
            $(this).toggleClass('active');

            let search = '';

            $('.filter.active').each(function () {
                let $filter = $(this),
                    type = $filter.data('type'),
                    value = $filter.data('value');

                if (value) {
                    if (search) {
                        search += '&' + type + '=' + value;
                    } else {
                        search += type + '=' + value;
                    }
                }
            });

            if (search) {
                window.location.href = window.location.href.replace(window.location.search, '') + '?' + search;
            } else {
                window.location.href = window.location.href.replace(window.location.search, '');
            }
        });
    }
}
